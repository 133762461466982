@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');
@import "fonts";
@import "vars";
@import "mixins";
@import "grid";
@import "forms";

/* Reset */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
  box-shadow: none;
}

hr {
  clear: both;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a,
input[type="submit"],
button {
  transition: $transition;
  cursor: pointer;
}

input[type="submit"],
button {
  border: 0;
  background: none;
}

input,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

img {
  max-width: calc(100% + .1px);
  height: auto;
}

/* General */

html, body {
  &.nooverflow {
    overflow: hidden !important;
  }
}

body {
  background: $white;
  color: $black;
  font: 16px/1.5 $font;
  min-width: 320px;
}

p, ul, ol, blockquote {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 40px;

  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 8px;
    border-radius: 5px;
    background: $purple;
    margin-top: 5px;
  }
}

h1, .h1 {
  font-size: 48px;
  line-height: 1.3;

  @include breakpoint (ltmd) {
    font-size: 40px;
  }

  @include breakpoint (xs) {
    font-size: 28px;
  };
}

h2, .h2 {
  font-size: 40px;
  line-height: 1.3;

  @include breakpoint (xs) {
    font-size: 28px;
  };
}

h3, .h3 {
  font-size: 24px;

  &::after {
    width: 40px;
    height: 4px;
  }
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 18px;
}

.ul {
  li {
    display: flex;
    align-items: flex-start;

    &::before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $black;
      margin-top: 9px;
      margin-right: 16px;
    }
  }
}

ol {
  margin-left: 24px;
}

.img-wrapper {
  font-size: 0;
  line-height: 0;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: none;
  }
}

// Links, buttons

.regular-link {
  color: $black;
  background: linear-gradient(to right, $purple 0%, $purple 100%) no-repeat 0 100% / 0 1px, linear-gradient(to right, $black 0%, $black 100%) no-repeat 0px 100% / 100% 1px;
  transition: all .2s ease-out;

  &:hover {
    color: $purple;
    background-size: 100% 1px, 100% 1px;
  }
}

.link {
  color: $black;
  background: linear-gradient(to right, $black 0%, $black 100%) no-repeat 0px 100% / 100% 1px;
  transition: all .2s ease-out;

  span {
    background: linear-gradient(to right, $red 0%, $red 100%) no-repeat 0px 100% / 0 100%;
    transition: all .2s ease-out;
    padding-bottom: 2px; //adjust according to the base line-height
    line-height: inherit;
  }

  &:hover {
    color: $black;

    span {
      background-size: 100% 100%;
    }
  }
}

.link-icon {
  display: inline-flex;
  align-items: center;

  i {
    margin-right: 8px;
    line-height: 0;

    &:last-child {
      margin: 0 0 0 8px;
    }
  }

  span {
    background: linear-gradient(to right, $black 0%, $black 100%) no-repeat 0px 100% / 0 1px;
    transition: all .2s ease-out;
    line-height: inherit;
  }

  &:hover {
    span {
      background-size: 100% 1px;
    }
  }
}

.button {
  transition: $transition2;
  overflow: hidden;
  position: relative;
  z-index: 1;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid $black;
  border-radius: 30px;
  background: $white;
  color: $black;

  &::before {
    content: '';
    display: block;
    background: $black;
    border-radius: 28px;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: $transition2;
    will-change: transform;
    z-index: -1;
  }

  &:hover,
  &.hover,
  &:focus-within {
    color: $white;

    &::before {
      transform: translate(0, 0);
    }
  }
}

.button-icon {
  @extend .button;

  i {
    margin-right: 16px;

    &:last-child {
      margin: 0 0 0 16px;
    }
  }
}

.buttons,
.links {
  &--horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: -16px;

    .link,
    .button {
      margin: 16px;
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .link,
    .button {
      & + .link,
      & + .button {
        margin-top: 16px;
      }
    }
  }
}

// Boxes

.boxes-row {
  margin-bottom: -24px;

  .box {
    @include breakpoint (lg) {
      height: calc(100% - 24px);
      margin-bottom: 24px;
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.box-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.box-img {
  @extend .img-wrapper;
}

.box-title {
  display: block;
}

a.box-title {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
  }

  span {
    background: linear-gradient(to right, $white 0%, $white 100%) no-repeat 0px 100% / 0 2px;
    transition: $transition2;
    line-height: inherit;
    padding-bottom: 4px;
  }

  &:hover {
    span {
      background-size: 100% 2px;
    }
  }
}



.header {
  padding-top: 24px;
  margin-bottom: 80px;

  @include breakpoint (lg) {
    background: linear-gradient(to right, $white 0, $white 70%, $purple 70%) no-repeat 0 24px / 100% 104px;
  };

  @include breakpoint (ltmd) {
    margin-bottom: 48px;
  }

  .container {
    @include breakpoint (ltlg) {
      width: 100%;
    };
  }

  .col-12 {
    display: flex;

    @include breakpoint (lg) {
      justify-content: space-between;
    };
  }

  .logo {
    display: flex;
    align-items: flex-start;
    color: $black;
    margin-right: 32px;

    @include breakpoint (ltmd) {
      img {
        width: 100px;
      }
    }

    @media (max-width: 400px) {
      span {
        display: none;
      }
    }

    span {
      position: relative;
      top: 20px;
      margin-left: 20px;

      @include breakpoint (exlg) {
        top: 10px;
      };

      @include breakpoint (ltmd) {
        top: 5px;
      }
    }

    &:hover {
      color: $purple;
    }
  }

  .mobile-nav-button {
    background: $black;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin-top: 24px;
    padding: 0 16px;

    @include breakpoint (ltmd) {
      margin-top: 5px;
    }

    span {
      margin-right: 16px;
      font-size: 18px;
    }

    i {
      font-size: 32px;
    }

    &.active {
      background: $purple;
    }

    @include breakpoint (xs) {
      width: 52px;

      span {
        display: none;
      }
    };
  }

  nav {
    flex-shrink: 0;

    @include breakpoint (lg) {
      display: flex;
      align-items: stretch;
      height: 104px;
    };

    @include breakpoint (ltlg) {
      display: none;
      width: 100%;
      max-width: 560px;
      position: absolute;
      top: 76px;
      right: 0;
      z-index: 1;
      box-shadow: $black 0 4px 10px;
    };

    @include breakpoint (ltmd) {
      top: 57px;
    }

    a {
      padding: 8px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      background: $purple linear-gradient(to bottom, $black 0, $black 100%) no-repeat center bottom / 100% 0;
      transition: background .5s ease;

      @include breakpoint (ltlg) {
        padding: 16px;

        & + a {
          border-top: 1px solid $black;
        }
      };

      &:hover,
      &.active {
        background-size: 100% 100%;
      }

      &.fb {
        font-size: 32px;
        background: $purple;
        transition: $transition;

        &:hover {
          color: $black;
        }
      }
    }
  }

  .mobile-fb {
    font-size: 32px;
    color: $black;
    margin: 28px 24px 0 auto;

    &:hover {
      color: $purple;
    }

    @include breakpoint (ltmd) {
      margin-top: 10px;
    }
  }
}

.intro {
  margin-bottom: 80px;

  .img--home {
    border: 1px solid $black;
    padding: 10px;
    border-radius: 4px;
    margin: 40px auto 0;
    display: block;
  }

  .right {
    font-style: italic;
    text-align: right;
  }
}

.boxes--home {
  display: flex;
  margin-bottom: 80px;

  @include breakpoint (ltlg) {
    flex-direction: column;
  };

  .box {
    width: calc(50% - 8px);
    flex-direction: row;
    align-items: stretch;

    @include breakpoint (ltlg) {
      width: 100%;
    };

    @include breakpoint (sm) {
      height: 300px;

      &:first-child {
        flex-direction: row-reverse;
      }
    };

    @include breakpoint (xs) {
      flex-direction: column;
    };

    & + .box {
      @include breakpoint (lg) {
        margin-left: 16px;
      };

      @include breakpoint (ltlg) {
        margin-top: 16px;
      };
    }

    .box-title {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $purple;
      color: $white;
      font-size: 32px;
      text-align: center;

      @include breakpoint (sm) {
        width: 40%;
      };

      &:hover {
        background: $black;
      }
    }

    .box-img {
      @include breakpoint (ltlg) {
        img {
          object-position: top;
        }
      };

      @include breakpoint (sm) {
        width: 60%;
      };

      @include breakpoint (xs) {
        height: 200px;
      };
    }
  }
}

.footer {
  border-top: 4px solid $purple;
  border-bottom: 4px solid $purple;
  background: $purple;

  @include breakpoint (md) {
    background: linear-gradient(to right, $white 0, $white 70%, $purple 70%) no-repeat 0 0 / 100% 100%;
  };

  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include breakpoint (ltmd) {
      flex-direction: column;
    };
  }

  .footer-left,
  .footer-center {
    padding: 32px 0;
  }

  .footer-left {
    @include breakpoint (ltmd) {
      display: none;
    };
  }

  .footer-center {
    @include breakpoint (ltlg) {
      display: none;
    };
  }

  nav {
    display: flex;
    flex-direction: column;
    padding: 8px 0 0 32px;
    border-left: 4px solid $purple;
    height: 100%;

    a {
      color: $purple;

      & + a {
        margin-top: 8px;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .footer-right {
    background: $purple;
    text-align: right;
    padding: 32px 0 32px 32px;

    span {
      display: block;

      & + span {
        margin-top: 8px;
      }

      &.contact-name {
        font-weight: 500;
        margin-bottom: 16px;
      }
    }

    .regular-link {
      background: linear-gradient(to right, $white 0%, $white 100%) no-repeat 0 100% / 0 1px, linear-gradient(to right, $black 0%, $black 100%) no-repeat 0 100% / 100% 1px;

      &:hover {
        color: $white;
        background-size: 100% 1px, 100% 1px;
      }
    }
  }
}

.subfooter {
  text-align: center;
  padding: 24px;
  font-size: 14px;
}

// Contact

.contact-address {
  display: flex;
  flex-direction: column;

  @include breakpoint (ltlg) {
    margin-bottom: 24px;
  };

  .contact-name {
    margin-bottom: 8px;
    font-weight: 700;
  }

  .grouper,
  span {
    display: flex;

    i {
      margin-right: 8px;
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    a {
      margin-left: 4px;
    }
  }

  span {
    align-items: center;
  }

  .grouper,
  span {
    & + span,
    & + .grouper {
      margin-top: 16px;
    }
  }
}

.contant-map {
  border: 0;
  width: 100%;
  height: 400px;
  margin: 80px 0;
}

// Lokale

.boxes--places {
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;

  .box {
    width: calc(33.33333% - 16px);
    align-items: stretch;
    height: 300px;
    margin: 0 8px 16px;

    @include breakpoint (ltlg) {
      width: calc(50% - 16px);
    };

    @include breakpoint (ltmd) {
      width: calc(100% - 16px);
    };

    .box-inner {
      border-top: 1px solid $black;
      background: rgba($purple, .7);
      color: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: $transition;
      padding-bottom: 24px;
      height: 88px;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: $white;
        margin-bottom: 24px;
      }

      .box-title {
        font-size: 24px;
        text-align: center;
        margin: 0 24px;
        color: $white;
      }

      .box-more {
        display: none;
        margin: auto 24px 0;
        align-self: flex-end;
      }
    }

    .box-img {
      width: 100%;
      height: 100%;
      background: $bg-grey;
    }

    &:hover {
      .box-inner {
        background: $black;
        height: 100%;

        p, span {
          display: block;
        }
      }
    }
  }
}

.li {
  padding-left: 14px;
  position: relative;

  &::before {
    background: $purple;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
  }

  & + li {
    margin-top: 8px;
  }
}

.article {
  overflow: hidden;
  margin-bottom: 80px;

  @include breakpoint (ltmd) {
    display: flex;
    flex-direction: column;
  }

  figure {
    @include breakpoint (md) {
      width: 50%;

      &.right {
        float: right;
        margin: 0 0 24px 24px;
      }

      &.left {
        float: left;
        margin: 0 24px 24px 0;
      }
    };

    @include breakpoint (ltmd) {
      order: 1;
      margin-top: 24px;
    }
  }

  figcaption {
    font-size: 12px;
    color: $border-grey2;
    text-align: right;

    a {
      @extend .regular-link;
      color: $border-grey2;
      background-image: linear-gradient(to right, $purple 0%, $purple 100%), linear-gradient(to right, $border-grey2 0%, $border-grey2 100%);
    }
  }

  img {
    border: 1px solid $black;
    padding: 10px;
    border-radius: 4px;
  }

  ul {
    li {
      @extend .li;
    }
  }
}

.fat {
  margin-bottom: 80px;

  @include breakpoint (xs) {
    margin-bottom: 24px;
  };
}

.img-zoomer {
  margin-bottom: 80px;
  margin-top: 64px;

  @include breakpoint (xs) {
    margin-top: 32px;
  };

  .img-zoomer-boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .img-zoomer-box {
    @extend .img-wrapper;
    position: relative;
    font-size: 0;
    line-height: 0;
    width: calc(25% - 2px);
    height: 250px;
    margin: 1px;

    @include breakpoint (ltxl) {
      width: calc(33.33333% - 2px);
    };

    @include breakpoint (ltmd) {
      width: calc(50% - 2px);
    };

    @include breakpoint (ltmd) {
      width: calc(50% - 2px);
      height: 200px;
    };
  }

  img {
    width: 100%;
  }

  .img-zoomer-zoomin {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    i {
      position: absolute;
      right: 24px;
      bottom: 24px;
      width: 40px;
      height: 40px;
      background: $purple;
      color: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      transition: $transition;
    }

    &:hover {
      background: rgba($black, .5);

      i {
        background: $black;
      }
    }
  }

  .img-zoomer-full {
    background: rgba($black, .9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;

    button {
      position: absolute;
      width: 40px;
      height: 40px;
      background: $purple;
      color: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;

      &:hover {
        background: $black;
      }
    }

    .img-zoomer-full-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      width: 100%;
      height: 100%;

      @include breakpoint (ltmd) {
        padding: 10px;
      };
    }

    .img-zoomer-full-img-wrapper {
      position: relative;
    }

    @keyframes pulse {
      0% {opacity: 0.1;}
      100% {opacity: 1;}
    }

    .img-zoomer-full-loader {
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      animation: pulse 1s infinite alternate-reverse;
      transform: translate(-50%, -50%);
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: calc(100vh - 80px);
      position: relative;
      border: 2px solid white;

      @include breakpoint (ltmd) {
        max-height: calc(100vh - 20px);
      };
    }

    .img-zoomer-prev,
    .img-zoomer-next {
      top: calc(50% - 20px);

      &.inactive {
        pointer-events: none;
        background: $border-grey;
      }

      i {
        top: 1px;
      }
    }

    .img-zoomer-prev {
      left: 24px;

      @include breakpoint (ltmd) {
        left: 10px;
      };

      i {
        left: -2px;
      }
    }

    .img-zoomer-next {
      right: 24px;

      @include breakpoint (ltmd) {
        right: 10px;
      };

      i {
        left: 1px;
      }
    }

    .img-zoomer-zoomout {
      top: 24px;
      right: 24px;

      @include breakpoint (ltmd) {
        top: 10px;
        right: 10px;
      };
    }
  }
}

.room-info {
  margin-bottom: 64px;
  display: flex;
  align-items: flex-start;

  @include breakpoint (ltmd) {
    flex-direction: column-reverse;
  };

  @include breakpoint (xs) {
    margin-bottom: 32px;
  };

  .room-info-left {
    .room-info-number {
      font-size: 20px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      i {
        margin-right: 16px;
        font-size: 24px;
      }

      span {
        top: -8px;
        position: relative;
        margin-left: 8px;
        font-size: 48px;
        color: $purple;
      }
    }

    .room-info-subtitle {
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      i {
        font-size: 24px;
        margin-right: 8px;
        top: -2px;
      }
    }

    ul {
      margin-left: 32px;

      li {
        @extend .li;
      }
    }
  }

  .room-info-right {
    background: $purple;
    color: $white;
    font-size: 24px;
    padding: 16px 32px;

    @include breakpoint (md) {
      text-align: right;
      margin-left: auto;
    };

    @include breakpoint (ltmd) {
      margin-bottom: 48px;
      width: 100%;
    };

    @include breakpoint (xs) {
      margin-bottom: 24px;
      padding: 16px;
    };

    span {
      @include breakpoint (md) {
        display: block;
      };
    }

    span:first-child {
      font-size: 32px;
    }

    span:last-child {
      font-size: 16px;
    }
  }
}

.room-elements {
  padding: 64px 0 40px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $purple;
  border-bottom: 1px solid $purple;

  @include breakpoint (xs) {
    padding: 32px 0 16px;
  };

  li {
    width: calc(50% - 20px);
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;

    @include breakpoint (xs) {
      width: 100%;
    };

    @include breakpoint (sm) {
      &:nth-child(2n+2) {
        margin-left: 40px;
      }
    }

    i {
      font-size: 24px;
      color: $purple;
      margin-right: 16px;
    }
  }
}
