@charset "UTF-8";

@font-face {
  font-family: "textp";
  src:url("../fonts/textp.eot");
  src:url("../fonts/textp.eot?#iefix") format("embedded-opentype"),
    url("../fonts/textp.woff") format("woff"),
    url("../fonts/textp.ttf") format("truetype"),
    url("../fonts/textp.svg#textp") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin icon {
  font-family: "textp" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

[data-icon]:before {
  @include icon;
  content: attr(data-icon);
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  line-height: 0;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  @include icon;
}

.icon-chevron-l:before {
  content: "\62";
}
.icon-chevron-r:before {
  content: "\63";
}
.icon-close:before {
  content: "\64";
}
.icon-arrow:before {
  content: "\65";
}
.icon-chevron-t:before {
  content: "\66";
}
.icon-chevron-b:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-house:before {
  content: "\61";
}
.icon-garden:before {
  content: "\69";
}
.icon-sleep:before {
  content: "\6a";
}
.icon-delux:before {
  content: "\6c";
}
.icon-bath:before {
  content: "\6d";
}
.icon-cold:before {
  content: "\6e";
}
.icon-shop:before {
  content: "\6f";
}
.icon-wifi:before {
  content: "\6b";
}
.icon-news:before {
  content: "\70";
}
.icon-drink:before {
  content: "\71";
}
.icon-food:before {
  content: "\72";
}
.icon-bike:before {
  content: "\73";
}
.icon-comp:before {
  content: "\74";
}
.icon-phone:before {
  content: "\75";
}
.icon-movie:before {
  content: "\76";
}
.icon-mail:before {
  content: "\77";
}
.icon-tv:before {
  content: "\78";
}
.icon-safe:before {
  content: "\79";
}
.icon-star:before {
  content: "\41";
}
.icon-car:before {
  content: "\7a";
}
.icon-person:before {
  content: "\42";
}
.icon-place:before {
  content: "\43";
}
.icon-menu:before {
  content: "\44";
}
.icon-fb:before {
  content: "\45";
}
