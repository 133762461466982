// Forms

.form-info {
  margin-bottom: 24px;

  p {
    margin-bottom: 8px;
  }
}

input,
textarea {
  &::-webkit-input-placeholder {
    color: $border-grey2;
  }
  &::-moz-placeholder {
    color: $border-grey2;
  }
  &:-ms-input-placeholder {
    color: $border-grey2;
  }
  &:-moz-placeholder {
    color: $border-grey2;
  }
}

.form-block,
.form-block-checkboxes,
.form-block-radios {
  margin-bottom: 24px;
  position: relative;

  &.invalid {
    .form-error {
      display: block;
    }

    label {
      color: $red;
    }
  }

  &:last-child {
    margin: 0;
  }
}

.form-block-info {
  color: $black;
  font-size: 12px;
  margin-top: 4px;
}

.form-error {
  color: $red;
  font-size: 12px;
  margin-top: 4px;
  display: none;
}

// Bottom section

.form-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .button + .button {
    margin-left: 24px;
  }

  & + .form-block-checkboxes {
    margin-top: 32px;
  }

  input[type="submit"] {
    background: none;
    border: 2px solid $purple;
    background: $purple;
    color: $white;
    height: 50px;
    border-radius: 50px;
    padding: 0 24px;
    font-size: 16px;
    min-width: 150px;

    &:hover {
      background: $black;
      color: $white;
    }
  }
}

// Label

label {
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  color: $border-grey;
  cursor: pointer;
}

// Inputs

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea,
.selectyummy .selectyummy-select {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border: 2px solid $border-grey;
  border-radius: 4px;
  background: $white;
  color: $black;
  transition: $transition;

  &.invalid,
  &:invalid,
  .form-block.invalid & {
    border-color: $red;
  }

  &[disabled] {
    background: $bg-grey;
  }

  &:focus {
    border-color: $purple;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"], {
  height: 48px;
  padding: 0 16px;
}

textarea {
  height: 150px;
  padding: 16px;
}

// Selects


// --- Selects ---

.base-select {
  display: none;
}

.selectyummy {
  position: relative;

  .selectyummy-select {
    padding: 16px;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    &::after {
      content: "+";
      font-size: 15px;
      margin-left: 16px;
      transition: $transition;
      position: relative;
      top: -2px;
    }
  }

  &.active .selectyummy-select {
    border-radius: 4px 4px 0 0;

    &::after {
      transform: rotate(135deg);
      top: 3px;
    }
  }

  .selectyummy-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    display: none;
    border: 1px solid $border-grey;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    background: $white;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 3px 10px rgba($black, .2);

    li {
      display: block;
      padding: 10px;
      border-bottom: 1px solid $border-grey;
      font-size: 13px;
      height: 36px;
      cursor: pointer;

      &:last-child{
        border-bottom: 0;
      }

      &:hover,
      &.hover{
        background: $bg-grey;
      }

      &.active{
        font-weight: bold;
      }

      &.disabled{
        user-select: none;
        -webkit-user-select: none;
        pointer-events: none;
        color: rgba($black, .5);
      }
    }
  }
}

// Checkbox, radio

.form-block-checkboxes,
.form-block-radios {
  label {
    position: relative;
    display: block;
    font: 700 14px/1.5 $font;

    & + label {
      margin-top: 8px;
    }
  }

  input {
    opacity: 0;
    position: absolute;
  }

  span {
    display: flex;
    align-items: flex-start;

    &::before {
      content: 'o';
      margin-right: 8px;
    }
  }

  input:checked + span::before {
    content: 'x';
  }
}

.form-block-radios {
  span {
    &::before {
      content: 'o';
    }
  }

  input:checked + span::before {
    content: 'x';
  }
}

// Wrappers

.button-wrapper {
  position: relative;
  padding: 0;
  border: 0;

  input[type="submit"] {
    background: none;
    border: 1px solid $black;
    color: $black;
    height: 40px;
    border-radius: 40px;
    padding: 0 56px 0 32px;
    font-size: 14px;
    transition: $transition;

    &:hover {
      color: $white;
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    font-size: 23px;
    pointer-events: none;
  }

  &:hover {
    color: $white;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $border-grey;
  background: $white;
  color: $black;
  padding: 0 16px;

  i {
    margin-right: 8px;
    font-size: 24px;
  }

  input {
    border: 0;
    background: none;
    padding: 0;
  }
}

.select-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $border-grey;
  background: $white;
  color: $black;
  padding: 0 16px;
  position: relative;

  .select-label {
    flex-shrink: 0;
    font-size: 16px;
    margin-right: 2px;
    pointer-events: none;
  }

  .select2-container--default,
  .select2-container--default.select2-container--focus {
    .select2-selection {
      border: 0;
      background: none;
      padding: 12px 0;
    }
  }

  .select2-container--default, .select2-container--default.select2-container--focus {
    left: 0 !important;
  }

  .select2-container--open .select2-dropdown {
    width: calc(100% + 2px) !important;
    left: -1px;
  }
}

.select-multiple-wrapper {
  color: $black;
  width: 50%;
  position: relative;

  .select-multiple-wrapper-bg {
    border-bottom: 1px solid $black;
    background: $bg-grey;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .select-multiple-wrapper-placeholder {
    position: absolute;
    top: 12px;
    padding: 0 16px;
    pointer-events: none;
  }

  .select-multiple-wrapper-active-label {
    display: none;
    font-size: 12px;
    position: absolute;
    top: 2px;
    padding: 0 16px;
    pointer-events: none;
    z-index: 1;
  }

  .select2-container {
    width: 100% !important;
  }

  .select2-container--default,
  .select2-container--default.select2-container--focus {
    height: auto;
    z-index: 1;

    .select2-selection {
      border-bottom: 0;
      background: none;
      padding: 0;
      height: auto;
      align-items: flex-start;

      .select2-selection__rendered {
        margin: 68px -8px -8px;

        .select2-selection__choice {
          margin: 0 8px 8px;
        }

        .select2-search--inline {
          position: absolute;
          top: 16px;
          left: 16px;

          input {
            margin: 0;
          }
        }
      }

      &::after {
        right: 11px;
        top: 11px;
        position: absolute;
      }
    }

    .select2-dropdown.select2-dropdown--above {
      bottom: calc(-100% + 48px);
    }

    &.select2-container--open,
    &.select2-container--focus {
      & ~ .select-multiple-wrapper-placeholder {
        display: none;
      }

      & ~ .select-multiple-wrapper-active-label {
        display: block;
      }
    }
  }

  .select2-container:not(.select2) {
    top: 48px !important;
  }

  &.select-multiple-wrapper--with-optgroups {
    .select2-container--default,
    .select2-container--default.select2-container--focus {
      .select2-selection .select2-selection__rendered .select2-selection__choice {
        display: none;
      }
    }
  }
}

.select-tags {
  .select-tag-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & + .select-tag-group {
      margin-top: 16px;
    }

    .select-tag-group-name {
      font-weight: 700;
      font-size: 14px;
      margin-right: 16px;
    }

    .select-tag {
      margin: 4px 8px;
      display: inline-flex;
      border-radius: 4px;
      align-items: center;
      color: $white;
      background: $border-grey;
      padding: 2px 3px 2px 8px;
      font-size: 14px;

      .select-tag-remove {
        margin-left: 10px;
        font-size: 0;

        &::after {
          content: "x";
          font-size: 21px;
          color: $white;
          transition: $transition;
        }

        &:hover {
          &::after {
            opacity: .6
          }
        }
      }
    }
  }
}

// Oneliner

.form--oneliner {
  display: flex;
  background: $bg-grey;
  border-radius: 40px;
  padding: 8px 8px 8px 24px;

  .form-block {
    flex-grow: 1;
  }

  input,
  .select2-container--default .select2-selection,
  .select2-container--default.select2-container--focus .select2-selection {
    height: 64px;
    border: 0;
    background: none;
  }

  .input-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;

    i {
      margin-right: 8px;
      font-size: 24px;
    }
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    border-radius: 40px 0 0 40px;
    color: $black;
  }

  .select-wrapper {
    margin-left: 16px;
    width: 240px;
    position: relative;
  }

  .button-wrapper {
    color: $white;
    margin-left: 16px;

    i {
      color: $white;
    }
  }

  input[type="submit"] {
    padding: 0 24px 0 56px;
    color: $white;
    background: $red;
    border-radius: 40px;

    &:hover {
      background: $black;
    }
  }
}
