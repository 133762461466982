
/* fonts */
$font: 'Ubuntu', sans-serif;

/* other */
$transition: all .5s ease;
$transition2: all .2s ease;

/* colors */
$white: #fff;
$black: #000;

$border-grey: #666;
$border-grey2: rgba(#666, .5);
$bg-grey: #eee;

$red: #ff004a;
$purple: #d50d6d;
